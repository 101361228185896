var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card-body",
    attrs: {
      "id": "divCard"
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "current_user"
    }
  }, [_vm._v(_vm._s(_vm.$t("witnessmodal.title")))]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.currentIndex,
      expression: "form.currentIndex"
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": "current_user"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "currentIndex", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, _vm.fillSaksiForm]
    }
  }, [_c('option', {
    attrs: {
      "value": "-2"
    }
  }), _c('option', {
    attrs: {
      "value": "-1"
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("witnessmodal.add-witness")) + " --")]), _vm._l(_vm.users, function (accessible, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": index
      }
    }, [_vm._v(" " + _vm._s(accessible.user.name) + " (" + _vm._s(accessible.accessible_type) + ") ")]);
  })], 2)]), _vm.form.currentIndex >= -1 ? _c('div', [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.name.$error
    }
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("fullname")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.name.$model,
      expression: "$v.form.name.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.$v.form.name.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.name, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.name.$error && !_vm.$v.form.name.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.name")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.ic_number.$error
    }
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("mykad")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('the-mask', {
    staticClass: "form-control",
    attrs: {
      "id": "ic_number",
      "mask": ['######-##-####']
    },
    model: {
      value: _vm.$v.form.ic_number.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.form.ic_number, "$model", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "$v.form.ic_number.$model"
    }
  }), _vm.$v.form.ic_number.$error && !_vm.$v.form.ic_number.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.mykad")) + " ")]) : _vm._e()], 1), _c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.email.$error
    }
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("email")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.email.$model,
      expression: "$v.form.email.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.$v.form.email.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.email, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.email.$error && !_vm.$v.form.email.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.email")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group mb-1",
    class: {
      'has-error': _vm.$v.form.address.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "exampleTextarea"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("address")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.address.$model,
      expression: "$v.form.address.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "rows": "2",
      "value": ""
    },
    domProps: {
      "value": _vm.$v.form.address.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.address, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.address.$error && !_vm.$v.form.address.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("address")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "row mt-3"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.postcode.$error
    }
  }, [_c('label', {
    staticClass: "d-block",
    attrs: {
      "for": "postcode"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("postcode")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.lazy.trim",
      value: _vm.postcode,
      expression: "postcode",
      modifiers: {
        "lazy": true,
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "postcode"
    },
    domProps: {
      "value": _vm.postcode
    },
    on: {
      "blur": [_vm.getPostcode, function ($event) {
        return _vm.$forceUpdate();
      }],
      "change": function ($event) {
        _vm.postcode = $event.target.value.trim();
      }
    }
  }), _vm.$v.form.postcode.$error && !_vm.$v.form.postcode.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.postcode")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "exampleSelect1"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("city")) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.city,
      expression: "form.city"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "readonly": ""
    },
    domProps: {
      "value": _vm.form.city
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "city", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "exampleSelect1"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("state")) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.state,
      expression: "form.state"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "readonly": ""
    },
    domProps: {
      "value": _vm.form.state
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "state", $event.target.value);
      }
    }
  })])])])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }